@use 'var' as agaStyles;

:root {
  bs-tooltip-container {
	.tooltip-arrow:before {
	  //content: '\25BC';
	  color: #E7E9F1;
	  position: absolute;
	  transform: scale(1.0, 0.5);
	  margin-top: -12px;
	  border: none;
	}

	.tooltip-inner {
	  color: agaStyles.$aga-light-primary;
	  background-color: #E7E9F1;
	  font-size: 10px;
	  font-weight: 700;
	  letter-spacing: 0;
	  line-height: 20px;
	  text-align: center;
	  margin-bottom: 5px;
	}
  }
}
