@use 'var' as agaStyles;

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: unset !important;
  margin-top: 65px;
  margin-right: 4px;
  border-radius: 20px 2px 20px 20px !important;

  &:has(.info) {
	background-color: rgb(204, 241, 231);

	svg g path {
	  fill: agaStyles.$aga-primary;
	}
  }

  &:has(.success) {
	background-color: rgb(204, 241, 231);

	svg g path {
	  fill: agaStyles.$aga-primary;
	}
  }

  &:has(.error) {
	background-color: rgb(246, 205, 214);

	svg g path {
	  fill: agaStyles.$aga-danger;
	}
  }
}

.dismiss-toast-button {
  width: 12px;
  float: right;
  cursor: pointer;

  &:hover {
	opacity: 0.8;
  }
}

.aga-custom-alert {
  .info-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: #{agaStyles.$aga-primary}15;
	border-radius: 50%;

	svg {
	  width: 20px;
	  height: 20px;
	}
  }

  .alert-message {
	font-size: 12px;
	line-height: 20px;
	color: agaStyles.$aga-primary;
	font-weight: 600;
  }
}
