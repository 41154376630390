@use 'var' as agaStyles;

/*Override mat dialog default design v17 update */
.mat-mdc-dialog-container {
  background: linear-gradient(180deg, rgba(agaStyles.$aga-primary, 0) 0%, rgba(agaStyles.$aga-primary, 0.05) 100%) agaStyles.$white !important;
  overflow-y: auto;

  .mdc-dialog__surface {
	width: unset;
	height: unset;
	min-width: unset;
	min-height: unset;
	overflow-y: unset;
	box-shadow: none !important;
	background: linear-gradient(180deg, rgba(agaStyles.$aga-primary, 0) 0%, rgba(agaStyles.$aga-primary, 0.05) 100%) agaStyles.$white;
  }
}

/*Override mat dialog v17 update */
.mat-mdc-dialog-container {
  width: 100%;
  padding: 24px;
  border-radius: 4px;
}

.confirmation-dialog .mat-mdc-dialog-container {
  width: 558px;
  padding: 20px;
  border-radius: 10px;
}

.time-divider-mode-options-dialog .mat-mdc-dialog-container {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.default-emails-options-dialog .mat-mdc-dialog-container {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.shared-dialog-options .mat-mdc-dialog-container {
  width: 400px;
  border-radius: 10px 0 0 10px;
  padding: 20px;
  box-shadow: 0 0 50px 0 rgba(16, 37, 114, 0.3);
}

.counselor-note-dialog .mat-mdc-dialog-container {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.treatment-edit-dialog .mat-mdc-dialog-container {
  width: 800px;
  border-radius: 10px 0 0 10px;
  padding: 20px;
  box-shadow: 0 0 50px 0 rgba(16, 37, 114, 0.3);
}

.treatment-preview-dialog .mat-mdc-dialog-container {
  width: 800px;
  padding: 20px;
  border-radius: 10px;
}

.refund-dialog .mat-mdc-dialog-container {
  width: 720px;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(16, 37, 114, 0.3);
}

.letter-of-reference-dialog .mat-mdc-dialog-container {
  width: 720px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(16, 37, 114, 0.3);
  background-color: #F1F2F7;
}

.note-for-operator-dialog .mat-mdc-dialog-container {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.combined-payment-dialog .mat-mdc-dialog-container {
  width: 600px;
  padding: 20px;
  border-radius: 10px;
}

.side-effect-dialog-options .mat-mdc-dialog-container {
  width: 720px;
  padding: 21px;
  border-radius: 10px;
}

.patient-registration-notes .mat-mdc-dialog-container {
  width: 600px;
  max-height: 95 svh;
  padding: 20px;
  border-radius: 10px;
}

.patient-medicine-stock-options .mat-mdc-dialog-container {
  width: 720px;
  padding: 21px;
  border-radius: 10px;
}
