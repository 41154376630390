// General colors
$white: #ffffff;
$black: #000000;

// Bootstrap overrides
$aga-font-family-sans-serif: 'Hiragino Sans', Roboto, 'Helvetica Neue', sans-serif;

:root {
  // override bootstrap default font family https://getbootstrap.com/docs/4.0/getting-started/theming/
  --font-family-sans-serif: #{$aga-font-family-sans-serif};
}

$aga-background: #f5f6fa;
$aga-foreground: #121212;
$aga-primary: #102572;
$aga-accent: #725d10;
$aga-accent-background: #e2f0f1;
$aga-links: #0091FF;
$aga-hr-color: #{$aga-primary}15;
$aga-light-primary: #13326D;
$aga-grey: #6E6E6E;

$aga-danger: #d10633;
$aga-danger-light: #ffdee0;
$aga-success: #00bb88;
$aga-success-light: #edf7ed;
$aga-info: #0d3c61;
$aga-info-light: #e8f4fd;
$aga-warning: #f7b500;

// Navbar
$aga-nav-bar-inactive-background-color: $aga-foreground;
$aga-nav-bar-active-background-color: #fff;

// Appointment location colors
$aga-online: #d4e9ff;
$aga-clinic: #fcd2ca;
$aga-salon: #fdf8aa;

// Event status colors
$aga-no-activity: #f5f6fa;
$aga-waiting: #e5ee9c;
$aga-counseling: #a6d6a7;
$aga-blood-examination-progress: #8fcaf9;
$aga-waiting-payment: #f9a09a;
$aga-ongoing: #ffe0b2;
$aga-finish: #eccbec;

// Call screen bg color
$aga-call-screen-background: #111b42;

// Search icon colors
$aga-primary-svg-fill: brightness(0%) invert(14%) sepia(26%) saturate(5669%) hue-rotate(213deg) contrast(99%);