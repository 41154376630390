@use 'var' as var;

.bs-datepicker {
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 0 50px 50px 0 rgba(var.$aga-primary, 0.3);
  background: linear-gradient(180deg, rgba(var.$aga-primary, 0) 0%, rgba(var.$aga-primary, 0.05) 100%);

  .bs-datepicker-container {
	padding: 15px 10px 20px;

	.bs-datepicker-head {
	  height: 60px;
	  border-bottom: 1px solid var.$aga-hr-color;

	  button {
		&.current {
		  width: 150px;
		  font-size: 16px;
		  font-weight: 500;
		  color: var.$aga-primary;
		  border-radius: 10px;
		  background-color: rgba(var.$aga-primary, 0.15);
		}

		&:has(~ [disabled]) {
		  opacity: 0.5;
		  cursor: not-allowed;
		  pointer-events: none;
		}
	  }
	}

	.bs-datepicker-body {
	  table {
		th {
		  font-size: 14px;
		  font-weight: 600;
		  color: var.$aga-foreground;
		  width: 48px !important;
		  height: 20px !important;
		}

		td {

		  span {
			margin: 2px;
			font-size: 16px;
			font-weight: 300;
			border-radius: 10px;
			color: var.$aga-primary;
			background-color: rgba(var.$aga-primary, 0.05);

			&.is-other-month {
			  background-color: var.$white;
			}

			&.selected {
			  font-weight: 600;
			  background-color: rgba(var.$aga-primary, 0.15);
			}
		  }
		}

		&.days, &.years {
		  span {
			width: auto;
		  }
		}
	  }
	}
  }
}
