@use 'var' as agaStyles;

timepicker {
  .bs-chevron-up, .bs-chevron-down {
	height: 12px;
	width: 14px;
	color: agaStyles.$aga-primary;
	margin: 0 16px;
  }

  .bs-timepicker-field {
	height: 56px;
	width: 56px;
	border: 1px solid rgba(16, 37, 114, 0.5);
	border-radius: 10px;
	background-color: agaStyles.$white;
	font-weight: 600;
	margin: 0 auto;
  }

  &.ng-invalid {
	input {
	  border-color: agaStyles.$aga-danger;
	}
  }
}
