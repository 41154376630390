@use 'var' as var;
@use '@angular/material' as mat;
@use 'aga-mat-palettes' as aga-palettes;
@use 'angular-material-overrides/mat-dialog';
@use 'angular-material-overrides/mat-snackbar';
@use 'ngx-bootstrap-overrides/tooltip';
@use 'ngx-bootstrap-overrides/timepicker';
@use 'ngx-bootstrap-overrides/datepicker';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Typography
$aga-typography: mat.define-legacy-typography-config(
	$font-family: var.$aga-font-family-sans-serif,
	$body-1: mat.define-typography-level(12px, 12px, 300),
	$headline: mat.define-typography-level(20px, 20px, 600),
	$title: mat.define-typography-level(16px, 16px, 600),
	$subheading-2: mat.define-typography-level(14px, 14px, 600),
	$subheading-1: mat.define-typography-level(12px, 12px, 600)
);
@include mat.typography-hierarchy($aga-typography);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// Material theme colors
$aga-frontend-primary: mat.define-palette(aga-palettes.$aga-primary-palette-map);
$aga-frontend-accent: mat.define-palette(aga-palettes.$aga-accent-palette-map, A200, A100, A400);
$aga-frontend-warn: mat.define-palette(aga-palettes.$aga-warn-palette-map);

$aga-frontend-theme: mat.define-light-theme((
	color: (
		primary: $aga-frontend-primary,
		accent: $aga-frontend-accent,
		warn: $aga-frontend-warn,
	),
	typography: $aga-typography
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aga-frontend-theme);

/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../../node_modules/bootstrap/scss/functions';
@import '../../../../../node_modules/bootstrap/scss/variables';
@import '../../../../../node_modules/bootstrap/scss/mixins';
@import 'assets/styles/text-styles';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  font-family: var.$aga-font-family-sans-serif;
  color: var.$aga-foreground;
}

.no-scroll-bar-on-overflow {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
	display: none; /* Chrome */
  }
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-pointer-events {
  pointer-events: none;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

/* Bootstrap overrides */
.container {
  max-width: 1920px !important;
}

/* End of Bootstrap overrides */

/* Loading indicator style */
.loading {
  opacity: 0.5;
  cursor: wait;

  &:active {
	pointer-events: none;
  }
}

/* Required field star style */
.required-field-star {
  &:after {
	content: ' *';
	color: var.$aga-danger;
  }
}
