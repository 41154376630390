@use 'var' as agaStyles;

$heading-color: agaStyles.$aga-light-primary;
$heading-font-weight: 600;

* {
  font-family: "Hiragino Sans", sans-serif;
}

/* Headings */
h1, h2, h3, h4 {
  color: $heading-color;
}

/* Headings */

b, strong {
  font-weight: 600;
}

/* Page Components */
th, .table-header {
  font-size: 12px;
  font-weight: 300;
  color: agaStyles.$aga-grey;
}

td, .table-list-item {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-foreground;
}

.side-panel-block-list-item {
  font-size: 14px;
  font-weight: 300;
  color: agaStyles.$aga-foreground;
}


@mixin employee-name {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-primary;
}

@mixin employee-seniority {
  font-size: 14px;
  font-weight: 300;
  color: agaStyles.$aga-primary;
}

@mixin appointment-number {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-primary;
}

/* Page Components */

/* Forms */
.inactive-form-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: agaStyles.$aga-primary;
}

.active-form-placeholder {
  font-size: 12px;
  font-weight: 300;
  color: agaStyles.$aga-primary;
}

.active-form-value {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-foreground;
}

/* Forms */

/* Tabs */
.inactive-tab {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-primary;
}

.active-tab {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$white;
}

/* Tabs */

/* Links */
.link {
  font-size: 14px;
  font-weight: 600;
  color: agaStyles.$aga-links !important;
  text-decoration: underline;
  cursor: pointer;
}

/* Links */

// Detects the "\n" new lines in a string
.text-preview-pre-line {
  white-space: pre-line;
}
